//Header Page css
.headerPage{height: 75px;}
.headerPage a{color: rgb(33,37,41); font-weight:bold; text-decoration: none;}
.headerPage .borderB {
    background: linear-gradient(#fff, #fff), linear-gradient(to right, #244cfd,#15e4fd);
    background-origin: padding-box, border-box;
    background-repeat: no-repeat;
    border-bottom: 3px solid transparent;
  }
.headerPage .dropdown-menu {right:0;}
// login Page css
.loginPage .loginOuter{
  position: fixed;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.8);
  z-index: 1040;
}  

.loginPage .loginOuter .loginInner{
    background-image: url('./../assets/img/logo.png');
    background-position: 97% 4%;
    background-size: 85px;
    background-color: #ffffff;
}

//Banner page css
.banner{
    height: 400px;
    background-image: url('./../assets/img/banner.png');
    background-position: center;
    background-size: cover;
}

//timeSlotPicker page
.timeSlotPicker .timeSlot{
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #244cfd;
  background-color: #ffffff;
  color: rgb(33,37,41);
  margin: 5px 5px;
  width: 90px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.timeSlotPicker .timeSlot.active{background-color: #244cfd; color:#fff; cursor: default;}
.timeSlotPicker .timeSlot#booked{background-color: #d3d3d3; cursor: default;}
//Department Box page
.departmentBoxHover{
  background-image: linear-gradient(to right, #fff,#15e4fd);
}

//Footer page css

.footerPage .socialIcon{display: inline-block; font-size: 35px; }
.footerPage .socialIcon a{color: #ffffff;}
.footerPage .socialIcon a:hover{color: #244cfd;}