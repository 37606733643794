body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto!important;
    font-size: 0.9rem;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .borderBottom{
    background: linear-gradient(#fff, #fff), linear-gradient(to right, #244cfd,#15e4fd);
    background-origin: padding-box, border-box;
    background-repeat: no-repeat;
    border-bottom: 3px solid transparent;
  }

  .borderPrimary{
    background: linear-gradient(#fff, #fff), linear-gradient(to right, #244cfd,#15e4fd);
    background-origin: padding-box, border-box;
    background-repeat: no-repeat;
    border: 3px solid transparent;
  }

  .borderDashed{
    border-bottom: 1px dashed #244cfd;
  }

  .primaryBg{background: linear-gradient(to right, #244cfd,#15e4fd);}
  .cursor{ cursor: pointer;}

  a, .nav-link{color: #244cfd;}
  .list-group-item.active{background-color: #244cfd;}

  .loadingOuter {    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255, 0.7);
    z-index: 9999;}

    .lds-heart {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      transform: rotate(45deg);
      transform-origin: 40px 40px;
    }
    .lds-heart div {
      top: 32px;
      left: 32px;
      position: absolute;
      width: 32px;
      height: 32px;
      background: #244cfd;
      animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .lds-heart div:after,
    .lds-heart div:before {
      content: " ";
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      background: #244cfd;
    }
    .lds-heart div:before {
      left: -24px;
      border-radius: 50% 0 0 50%;
    }
    .lds-heart div:after {
      top: -24px;
      border-radius: 50% 50% 0 0;
    }
    @keyframes lds-heart {
      0% {
        transform: scale(0.95);
      }
      5% {
        transform: scale(1.1);
      }
      39% {
        transform: scale(0.85);
      }
      45% {
        transform: scale(1);
      }
      60% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(0.9);
      }
    }